<template>
  <div>
    <v-file-input
      :id="fieldId"
      style="display: none"
      accept="image/*"
      color="primary"
      prepend-icon="mdi-image"
      @change="onClick($event)"
    ></v-file-input>
    <label :for="fieldId">
      <v-card
        outlined
        @drop.prevent="onDrop($event)"
        @dragover.prevent="onDragOver($event)"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="{
          'green lighten-2': dragover & isAllowedType,
          'red lighten-2': dragover & !isAllowedType,
        }"
        style="cursor: pointer"
      >
        <v-card-text>
          <v-row
            class="d-flex flex-column"
            dense
            align="center"
            justify="center"
          >
            <v-icon
              class="primary--text"
              :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
              size="60"
              >mdi-cloud-upload
            </v-icon>
            <h4>
              {{ $t("mosaic.tileSet.uploadTitle") }}
            </h4>
          </v-row>
          <v-virtual-scroll
            v-if="uploadedFiles.length > 0"
            :items="uploadedFiles"
            height="300"
            item-height="50"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                    <span class="ml-3 text--secondary">
                      {{ item.size }} bytes</span
                    >
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn @click.prevent="removeFile(item.name)" icon>
                    <v-icon class="primary--text"> mdi-close-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card-text>
      </v-card>
    </label>
    <error-alert
      :show="showAlertUpload"
      :error="upload.success"
      :message="this.upload.message"
    ></error-alert>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ErrorAlert from "@/components/error/ErrorAlert";

export default {
  name: "UploadFiles",
  components: {
    ErrorAlert,
  },
  props: {
    fieldId: {
      type: String,
      require: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    allowedTypes: {
      type: Array,
      default: () => ["image/png", "image/jpeg"],
    },
    isVisitorAccount: {
      type: Boolean,
      default: true,
    },
    addFiles: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: [],
      isAllowedType: false,
      upload: { success: false, message: "" },
      showAlertUpload: false,
    };
  },
  methods: {
    ...mapActions({}),
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) {
        this.uploadedFiles.splice(index, 1);
        this.addFiles(this.uploadedFiles);
      }
    },
    onDragOver(e) {
      this.dragover = true;
      const items = e.dataTransfer.items;
      let allowed = true;
      for (const item of items) {
        if (!this.allowedTypes.includes(item.type)) {
          allowed = false;
          break;
        }
      }
      this.isAllowedType = allowed;
    },
    onClick(e) {
      if (!this.multiple && this.uploadedFiles.length > 0) {
        this.uploadedFiles = [];
      }
      this.uploadedFiles.push(e);
      this.addFiles(this.uploadedFiles);
    },
    onDrop(e) {
      this.dragover = false;
      if (!this.isAllowedType) return;
      if (!this.multiple && e.dataTransfer.files.length > 1) return;
      if (e.dataTransfer.files) {
        Object.values(e.dataTransfer.files).forEach((element) => {
          this.uploadedFiles.push(element);
        });
      }
      this.addFiles(this.uploadedFiles);
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.v-card {
  border-style: dashed;
}
</style>
