<template>
  <v-card elevation="20">
    <v-card-title>
      <slot name="title" />
    </v-card-title>
    <v-card-text>
      <v-container>
        <slot name="content" />
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TabContent",
};
</script>

<style scoped lang="scss"></style>
