<template>
  <v-navigation-drawer
    app
    clipped
    temporary
    v-model="drawer"
    :style="`margin-top: ${$vuetify.application.top}px`"
  >
    <v-list nav>
      <v-list-item
        v-for="(item, index) in $store.state.general.nav"
        :key="'nav' + index"
        link
        :to="item.route"
      >
        <v-list-item-icon>
          <v-icon class="primary--text">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="primary--text">{{
            $t(`navItem.${item.label}`)
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "NavDrawer",
  components: {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.general.drawer;
      },
      set(val) {
        this.$store.state.general.drawer = val;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
};
</script>
<style lang="scss" scoped>
.v-list-item--disabled {
  background-color: rgba(211, 211, 211, 0.5);

  .v-list-item__content > .drawer-item-label {
    color: rgba(65, 65, 65, 0.8) !important;
  }

  .drawer-item-icon > .v-icon {
    color: rgba(65, 65, 65, 0.8) !important;
  }
}

.v-list-item__icon :not(.icon-lock) > .v-icon.primary--text {
  color: #ce2121;
}
</style>
