<template>
  <v-app-bar app dense clipped-left>
    <!--    <v-app-bar-nav-icon @click="setDrawer(!drawer)" />-->
    <v-toolbar-title> André Schumacher </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
      class="primary--text no-uppercase"
      elevation="0"
      fab
      small
      @click="setTheme()"
    >
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
    <v-btn
      class="primary--text no-uppercase"
      elevation="0"
      fab
      small
      @click.prevent="setLanguage"
    >
      <span>{{ this.$i18n.locale.toUpperCase() }}</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "AppBar",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      drawer: "general/getDrawer",
    }),
    language: {
      get() {
        return this.user.language === "de_DE" ? "en" : "de";
      },
      set(value) {
        this.user.language = value === "de" ? "de_DE" : "en_GB";
      },
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapMutations({
      setDrawer: "general/showDrawer",
    }),
    ...mapActions({}),
    setTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    setLanguage() {
      this.$i18n.locale = this.$i18n.locale === "en" ? "de" : "en";
    },
  },
};
</script>

<style lang="scss" scoped></style>
