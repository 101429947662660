<template>
  <tab-content>
    <template v-slot:content>
      <v-row>
        <v-col cols="12" lg="6">
          <v-card elevation="20">
            <v-card-title>
              <v-row>
                <v-col cols="12" lg="8">Web Development Schumacher</v-col>
                <v-spacer />
                <v-col cols="12" lg="4">{{
                  $t("cv.schumacher.timeline")
                }}</v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>{{ $t("cv.schumacher.position") }}</v-col>
                </v-row>
                <v-row>
                  <v-col>{{ $t("cv.schumacher.description") }}</v-col>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.languages") }}
                    </span>
                    Javascript, Typescript
                  </div>
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.frameworks") }}
                    </span>
                    NodeJS, Vue
                  </div>
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.tools") }}
                    </span>
                    GraphQL, Sequelize, Docker
                  </div>
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.dbs") }}
                    </span>
                    Postgres
                  </div>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.projects") }}
                    </span>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="mr-2">-</div>
                    <div>
                      {{ $t("cv.schumacher.p1") }}
                    </div>
                  </div>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.devops") }}
                    </span>
                  </div>
                  <div class="flex-column ml-5">
                    <div>{{ $t("cv.vrtualx.deploy") }}</div>
                    <div class="d-flex flex-column ml-6">
                      <div>{{ $t("cv.vrtualx.d1") }}</div>
                      <div>{{ $t("cv.vrtualx.d2") }}</div>
                      <div>{{ $t("cv.vrtualx.d3") }}</div>
                      <div>{{ $t("cv.vrtualx.d4") }}</div>
                      <div>{{ $t("cv.vrtualx.d5") }}</div>
                    </div>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card elevation="20">
            <v-card-title>
              <v-row>
                <v-col cols="5" lg="4">VRtual-X GmbH</v-col>
                <v-spacer />
                <v-col cols="7" lg="4">Sep 2020 — Feb 2024</v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>{{ $t("cv.vrtualx.position") }}</v-col>
                </v-row>
                <v-row>
                  <v-col>{{ $t("cv.vrtualx.description") }}</v-col>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.languages") }}
                    </span>
                    Javascript, Typescript
                  </div>
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.frameworks") }}
                    </span>
                    NodeJS, Vue, Angular
                  </div>
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.tools") }}
                    </span>
                    GraphQL, Sequelize, Docker
                  </div>
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.dbs") }}
                    </span>
                    Postgres, MongoDB
                  </div>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.projects") }}
                    </span>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="mr-2">1.</div>
                    <div>
                      {{ $t("cv.vrtualx.p1") }}
                    </div>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="mr-2">2.</div>
                    <div>
                      {{ $t("cv.vrtualx.p2") }}
                    </div>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="mr-2">3.</div>
                    <div>
                      {{ $t("cv.vrtualx.p3") }}
                    </div>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="mr-2">4.</div>
                    <div>
                      {{ $t("cv.vrtualx.p4") }}
                    </div>
                  </div>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.devops") }}
                    </span>
                  </div>
                  <div class="flex-column ml-5">
                    <div>{{ $t("cv.vrtualx.deploy") }}</div>
                    <div class="d-flex flex-column ml-6">
                      <div>{{ $t("cv.vrtualx.d1") }}</div>
                      <div>{{ $t("cv.vrtualx.d2") }}</div>
                      <div>{{ $t("cv.vrtualx.d3") }}</div>
                      <div>{{ $t("cv.vrtualx.d4") }}</div>
                      <div>{{ $t("cv.vrtualx.d5") }}</div>
                    </div>
                  </div>
                  <div class="ml-5">{{ $t("cv.vrtualx.admin") }}</div>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card elevation="20">
            <v-card-title>
              <v-row>
                <v-col cols="5" lg="4">Fleno GmbH</v-col>
                <v-spacer />
                <v-col cols="7" lg="4">May 2014 — Sep 2020</v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col> Fullstack Web Developer </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    {{ $t("cv.fleno.description") }}
                  </v-col>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.languages") }}
                    </span>
                    PHP, Python
                  </div>
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.frameworks") }}
                    </span>
                    {{ $t("cv.fleno.frameworks") }}
                  </div>
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.dbs") }}
                    </span>
                    MySQL
                  </div>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    <span class="text-button ml-3">
                      {{ $t("cv.projects") }}
                    </span>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="mr-2">1.</div>
                    <div>
                      {{ $t("cv.fleno.p1") }}
                    </div>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="mr-2">2.</div>
                    <div>
                      {{ $t("cv.fleno.p2") }}
                    </div>
                  </div>
                  <div class="d-flex ml-5">
                    <div class="mr-2">3.</div>
                    <div>
                      {{ $t("cv.fleno.p3") }}
                    </div>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </tab-content>
</template>

<script>
import TabContent from "@/components/UI/TabContent.vue";

export default {
  name: "CvProfessional",
  components: { TabContent },
};
</script>

<style scoped lang="scss"></style>
