<template>
  <v-container>
    <v-tabs grow align-with-title>
      <v-tab>{{ $t("cv.contact") }}</v-tab>
      <v-tab>{{ $t("cv.professional") }}</v-tab>
      <v-tab>{{ $t("cv.education") }}</v-tab>
      <v-tab-item><cv-contact /></v-tab-item>
      <v-tab-item><cv-professional /></v-tab-item>
      <v-tab-item><cv-education /></v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import CvContact from "@/components/cv/CvContact.vue";
import CvProfessional from "@/components/cv/CvProfessional.vue";
import CvEducation from "@/components/cv/CvEducation.vue";

export default {
  name: "HomeView",
  components: { CvEducation, CvProfessional, CvContact },
};
</script>
