<template>
  <tab-content>
    <template v-slot:content>
      <v-row>
        <v-col cols="12" lg="3">
          <v-card elevation="20">
            <v-card-text>
              <v-img :src="require('../../assets/img/profile.jpg')" contain />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card elevation="20">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <icon-button
                      icon="mdi-github"
                      href="https://github.com/WebDevSchumacher"
                      target="_blank"
                    />
                  </v-col>
                  <v-col cols="8"> https://github.com/WebDevSchumacher </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <icon-button
                      icon="mdi-email"
                      href="mailto:mail@andre-schumacher.com"
                    />
                  </v-col>
                  <v-col cols="6"> mail@andre-schumacher.com </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <icon-button icon="mdi-phone" href="tel:+48575771435" />
                  </v-col>
                  <v-col cols="6">
                    <p>+48 575 771 435</p>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <icon-button icon="mdi-phone" href="tel:+4915903154921" />
                  </v-col>
                  <v-col cols="6">
                    <p>+49 1590 315 4921</p>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <icon-button
                      icon="mdi-linkedin"
                      href="https://www.linkedin.com/in/andre-schumacher-768b991a8/"
                      target="_blank"
                    />
                  </v-col>
                  <v-col cols="6">
                    <p>
                      <a
                        href="https://www.linkedin.com/in/andre-schumacher-768b991a8/"
                        target="_blank"
                      >
                        LinkedIn
                      </a>
                    </p>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-col>
                    <v-spacer />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </template>
  </tab-content>
</template>

<script>
import TabContent from "@/components/UI/TabContent.vue";
import IconButton from "@/components/UI/iconButton.vue";

export default {
  name: "CvContact",
  components: { IconButton, TabContent },
};
</script>

<style scoped lang="scss"></style>
