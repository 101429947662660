<template>
  <v-app class="ma-0">
    <nav-drawer />
    <app-bar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavDrawer from "@/components/layout/NavDrawer";
import AppBar from "@/components/layout/AppBar";
export default {
  name: "App",
  components: { AppBar, NavDrawer },
  data: () => ({}),
};
</script>
