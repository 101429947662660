<template>
  <v-card elevation="20">
    <v-card-title>
      {{ $t("mosaic.create") }}
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="selected"
        :items="sets"
        :return-object="true"
        hide-selected
        outlined
        :menu-props="{ bottom: true, offsetY: true }"
        @blur="$v.selected.$touch()"
        @input="$v.selected.$touch()"
        :label="$t('select set')"
        item-text="label"
      />
      <upload-files
        :add-files="addFile"
        :multiple="false"
        field-id="createMosaicUpload"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import UploadFiles from "@/components/upload/UploadFiles";
export default {
  name: "CreateMosaic",
  components: { UploadFiles },
  data() {
    return {
      selected: null,
      file: null,
    };
  },
  validations: {
    selected: {
      required,
      $autoDirty: true,
    },
  },
  computed: {
    ...mapGetters({
      sets: "tileSet/getTileSets",
    }),
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions({
      createMosaic: "mosaic/createMosaic",
    }),
    addFile(file) {
      this.file = file;
    },
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.file) {
        await this.createMosaic({
          tileSet: this.selected.id,
          file: this.file.pop(),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
