<template>
  <v-btn
    color="accentDark"
    v-on="handlers"
    :disabled="disabled"
    class="mr-4"
    :fab="fab"
    :small="fab"
    v-bind="$attrs"
  >
    <v-icon v-if="iconLeft && !fab" left>
      {{ iconLeft }}
    </v-icon>
    {{ fab || icon ? "" : text }}
    <v-icon v-if="icon">
      {{ icon }}
    </v-icon>
    <v-icon v-if="iconRight && !fab" right>
      {{ iconRight }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    text: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconLeft: {
      type: String,
      required: false,
    },
    iconRight: {
      type: String,
      required: false,
    },
    clickAction: {
      type: Function,
      required: false,
    },
    disableCondition: {
      type: Function,
      required: false,
    },
    additionalHandlers: {
      type: Object,
      required: false,
    },
    fab: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    disabled() {
      return this.disableCondition ? this.disableCondition() : false;
    },
  },
  data() {
    return {
      handlers: {
        click: this.clickAction,
        ...this.additionalHandlers,
      },
    };
  },
};
</script>
<style scoped></style>
