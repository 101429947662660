<template>
  <div class="px-5 pt-5">
    <v-alert
      v-if="show"
      class="white--text caption pa-1"
      :color="error === true ? 'primary' : 'red'"
      :type="error === true ? 'success' : 'error'"
      outlined
    >
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "ErrorAlert",
  props: ["show", "error", "message"],
};
</script>
<style scoped></style>
