<template>
  <tab-content>
    <template v-slot:content>
      <v-row>
        <v-col cols="12" lg="6">
          <v-card elevation="20">
            <v-card-title>
              <v-row>
                <v-col cols="10" lg="7">{{ $t("cv.bachelor.title") }}</v-col>
                <v-spacer />
                <v-col cols="8" lg="4">{{ $t("cv.bachelor.timeline") }}</v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    {{ $t("cv.bachelor.uni") }}
                  </v-col>
                </v-row>
                <v-row class="flex-column">
                  <div>{{ $t("cv.bachelor.major") }}</div>
                  <div class="flex-column">
                    <div>{{ $t("cv.bachelor.projects") }}</div>
                    <div class="d-flex flex-column ml-2">
                      <div>MEAN Stack (Javascript/Typescript)</div>
                      <div>.NET (C#)</div>
                      <div>Go</div>
                      <div>Unity (C#)</div>
                      <div>Elm</div>
                    </div>
                  </div>
                  <div class="flex-column">
                    <div>{{ $t("cv.bachelor.thesis") }}</div>
                    <div>{{ $t("cv.bachelor.thesisTitle") }}</div>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card elevation="20">
            <v-card-title>
              <v-row>
                <v-col cols="8" lg="7">
                  {{ $t("cv.training.title") }}
                </v-col>
                <v-spacer />
                <v-col cols="8" lg="4">{{ $t("cv.training.timeline") }}</v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>{{ $t("cv.training.school") }}</v-col>
                </v-row>
                <v-row class="flex-column">
                  <div>
                    {{ $t("cv.training.description") }}
                  </div>
                  <div>
                    {{ $t("cv.training.short") }}
                  </div>
                  <div>
                    {{ $t("cv.training.honours") }}
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </tab-content>
</template>

<script>
import TabContent from "@/components/UI/TabContent.vue";

export default {
  name: "CvEducation",
  components: { TabContent },
};
</script>

<style scoped lang="scss"></style>
